#application {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 60px;
  color: white;
  margin-bottom: 20px;
  padding-top: 2%;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2% 2%;
  box-sizing: border-box;
}

#navigationbar {
  position: absolute;
  top: 10;
  left: 0;
  width: 100%;
}


.tagCloudContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


#wordcloud {
  margin: 30px 0;
}

#bookmarksByTagList {
  width: 50%;
}

#bookmarksByTagList h2 {
color: white;
text-decoration: underline;
}


.container:hover .buttonContainer {
  display: block; 
}

.buttonContainer button:hover {
  background-color: #5f6877
}


@media (min-width: 800px) {

  main {
    width: 80%;
  }

}

@media (min-width: 1000px) {

  main {
    width: 80%;
    padding: 2% 15%;
  }

}